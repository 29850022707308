<template>
  <div>
    <!-- Table Families Card -->
    <b-card>
      <validation-observer ref="simple">

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="title"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الاسم التجاري</label>
                    <b-form-input
                      id="input-title"
                      v-model="Form.name"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
               <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label-for="title"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  المفوض بالتسليم</label>
                    <b-form-input
                      id="input-title"
                      v-model="Form.assisted"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="code"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الرقم</label>
                    <b-form-input
                      id="input-default"
                      v-model="Form.phone"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="الايميل"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="email|required"
                  >
                    <b-form-input
                      id="email"
                      v-model="Form.email"
                      type="email"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="العنوان"
                  label-for="location"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="location"
                    rules="required"
                  >
                    <b-form-input
                      id="location"
                      v-model="Form.location"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
                  <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="المجال"
                  label-for="field"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="field"
                    rules=""
                  >
                    <b-form-input
                      id="field"
                      v-model="Form.field"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
                  <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="المالك"
                  label-for="owner"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="owner"
                    rules=""
                  >
                    <b-form-input
                      id="owner"
                      v-model="Form.owner"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
                  <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="الكود"
                  label-for="code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="code"
                    rules=""
                  >
                    <b-form-input
                      id="code"
                      v-model="Form.code"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
                <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="تاريخ البداية"
                  label-for="code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="code"
                    rules=""
                  >
                      <flat-pickr v-model="Form.beginning_date"    @input="(val) => $emit('update:dateFilter', val)"    class="form-control"
                    placeholder="حدد تاريخ " />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="brand"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  التفاصيل</label>
                    <b-form-input
                      id="input-brand_name"
                      v-model="Form.notes"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                class="border-Primary "
                md="12"
                xl="12"
              >
                <b-button

                  variant="outline-primary"
                  type="submit"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle"> إضافة المورد</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </validation-observer>

    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="app-action">
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="
                d-flex

                mb-1 mb-md-0
              "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>SortBy</label>
                <v-select
                style="width:200px"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  v-model="sort"
                  multiple
                  :clearable="true"
                   />
             -->
          </b-col>
        </b-row>
      </div>

      <!-- Update Mail Folder Dropdown -->

      <b-table
        ref="refFamiliesListTable"
        :items="fetchVendor"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
      >
        <!-- Column: editfield -->

        <!-- Column: Actions -->
        <template #cell(action_id)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item
             :to="{ name: 'edit-vendor', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>
          <b-dropdown-item
            role="button"
            @click="deleteVendor(data.item.id)"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span class="align-middle ml-50">حذف</span>
          </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormInvalidFeedback,
  BDropdownItemButton,
  BPagination,
  BFormCheckbox,
} from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import vendorStoreModule from './vendorStoreModule.js'
import useRequestItemList from './vendorList'
import store from '@/store'

export default {
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const VENDOR_APP_STORE_MODULE_NAME = 'app-vendor'

    // Register module
    if (!store.hasModule(VENDOR_APP_STORE_MODULE_NAME)) { store.registerModule(VENDOR_APP_STORE_MODULE_NAME, vendorStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VENDOR_APP_STORE_MODULE_NAME)) { store.unregisterModule(VENDOR_APP_STORE_MODULE_NAME) }
    })
    // Mail Selection
    // ------------------------------------------------
    const Form = ref({
      name: '',
      phone: '',
      email: '',
      assisted: '',
      location: '',
      notes: '',
      beginning_date:''
    })
    const onSubmit = () => {
      //console.log(Form)

      store.dispatch('app-vendor/CreateVendor', Form.value).then(response => {
        Form.value.name = ''
        Form.value.phone = ''
        Form.value.notes = ''
        Form.value.email = ''
        Form.value.location = ''
         Form.value.field = ''
        Form.value.owner = ''
        Form.value.code = ''
         Form.value.assisted = ''
        resetForm()
        refetchData()
        Vue.swal({
          title: '',
          text: 'تمت أضافة المورد بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }
    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchVendor,

      selectedItem,
      deleteVendor,
      // Extra Filters
    } = useRequestItemList()

    return {
      deleteVendor,
      onSubmit,
      refFormObserver,
      resetForm,
      Form,
      getValidationState,
      // Sidebar

      search: null,
      fetchVendor,

      filterOn: [],
      searchTerm: '',
      tableColumns,
      selectedItem,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
    }
  },

  components: {
    BFormGroup,
    BForm,
    flatPickr,
    BDropdownItemButton,
    BInputGroup,
    required,
    BFormInvalidFeedback,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BFormCheckbox,
  },
  methods: {
    approveAll() {
      //console.log(this.selectedItem)
    },
  },
}
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.app-action {
  margin: 18px;
}
.d-block.invalid-feedback {
    color: red;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
